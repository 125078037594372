import axios from 'axios';

class MyFlashDS {

  // Roles = {ADMIN: 1, SECURITY:2, PLUS: 4, SUBSCRIPTION: 8, VALIDATED: 16};
  identifyUser() {
    console.log('identifyUser', process.env.VUE_APP_ROOT_API)
    return axios.get(process.env.VUE_APP_ROOT_API+'/users');
  }

  rncpFindAll() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/rncp/findAll');
  }

  groupFindOne(group_id) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/group', {params:{id: group_id}});
  }
  groupForOrg(org_id) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/group/findForOrg', {params:{org_id: org_id}});
  }
  groupSaveCreate(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/group', data);
  }

  organisationFindOne(params) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/organisation', {params: params});
  }
  organisationFindAll() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/organisation/findAll');
  }
  organisationSave(data) {
    return axios.patch(process.env.VUE_APP_ROOT_API+'/organisation/', data);
  }
  organisationSaveCreate(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/organisation', data);
  }


  // Récupère la liste complète des techno
  technoFindAll() {
    return axios.get(process.env.VUE_APP_ROOT_API+'/techno/findAll');
  }
  technoSave(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/techno', data);
  }

  questionGet(params) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/question', {params:params});
  }
  questionFindAll(params) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/question/findAll', {params:params});
  }
  questionSave(data) {
    return axios.patch(process.env.VUE_APP_ROOT_API+'/question', data);
  }
  questionCheck(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/question/check', data);
  }

  // Enregistre et note une réponse à une question
  answerPost(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/answer', data);
  }
  // Récupère l'historique des réponse d'un utilisateur
  answerGet(params) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/answer', {params:params});
  }
  answerSynthese(techno) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/answer/synthese', {params:{techno:techno}});
  }

  // Update user
  userSave(data) { // was saveUser
    //console.log('>MyFlashDS.userSave', data)
    return axios.patch(process.env.VUE_APP_ROOT_API+'/users', data);
  }

  userInvite(data) { //
    return axios.post(process.env.VUE_APP_ROOT_API+'/users/invite', data);
  }
  userFindByTechno(params) { //
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/findByTechno', {params:params});
  }

  configGet(fields) {
    return axios.get(process.env.VUE_APP_ROOT_API+'/config?fields='+fields);
  }



  //===================================================================================================================

  registerUser(data) {
    console.log('>MyFlashDS.registerUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users', data);
  }

  validateUser(data) {
    console.log('>MyFlashDS.validateUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/validate', data);
  }
  forgotUser(data) {
    console.log('>MyFlashDS.forgotUser', data);
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/forgot', data);
  }

  loginUser(data) {
    console.log('>MyFlashDS.loginUser', data)
    return axios.post(process.env.VUE_APP_ROOT_API+'/users', data);
  }


  logoutUser() {
    console.log('>MyFlashDS.logoutUser', process.env.VUE_APP_ROOT_API)
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/logout');
  }


  //------------------------------------------------------------------

  dataGetUser(id, fields) {
    console.log('>MyFlashDS.dataGetUser', id);
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/userData', {params:{id:id, fields: fields}});
  }
  dataSetUser(data) {
    console.log('>MyFlashDS.dataSetUser');
    return axios.put(process.env.VUE_APP_ROOT_API+'/users/userData', data);
  }
  // Liste de tous les utilisateurs
  getAllUsers(group_id) {
    console.log('>MyFlashDS.getAllUsers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/findAll', {params:{group_id:group_id}});
  }
  dataGetFlash(no) {
    console.log('>MyFlashDS.dataGetFlash');
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags/getData?no='+no);
  }

  //------------------------------------------------------------------

  getUsers() {
    console.log('>MyFlashDS.getUsers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/allUsers');
  }

/*
  test() {
    console.log('>MyFlashDS.test');
    //axios.get(process.env.VUE_APP_ROOT_API+'/users/test'); // OK
    return axios.get(process.env.VUE_APP_ROOT_API+'/users/test'); // OK
  }
*/

  //------------------------------------------------------------------

  getTags() {
    console.log('>MyFlashDS.getTags');
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags');
  }
  findTag(params){//no, id, cnt
    console.log('>MyFlashDS.findTag');
    return axios.get(process.env.VUE_APP_ROOT_API+'/tags/findOne', {params});
  }
  updateTags(data) { // {nos: '10-12', customer:'ID'}
    console.log('>MyFlashDS.updateTags');
    return axios.post(process.env.VUE_APP_ROOT_API+'/tags', data);
  }
  saveTag(data) { // {tagid:'***'}
    console.log('>MyFlashDS.ownerTag');
    return axios.put(process.env.VUE_APP_ROOT_API+'/tags', data);
  }

  //------------------------------------------------------------------

  getCustomers(type) {
    console.log('>MyFlashDS.getCustomers');
    return axios.get(process.env.VUE_APP_ROOT_API+'/customers/findAll?type='+type);
  }
  getOneCustomer(id,proj) {
    console.log('>MyFlashDS.getOneCustomer', id, proj);
    return axios.get(process.env.VUE_APP_ROOT_API+'/customers?id='+id+'&proj='+proj);
  }
  createCustomers(data) {
    console.log('>MyFlashDS.addCustomers');
    return axios.post(process.env.VUE_APP_ROOT_API+'/customers', data);
  }
  saveCustomer(data) { //
    console.log('>MyFlashDS.saveCustomer');
    return axios.put(process.env.VUE_APP_ROOT_API+'/customers', data);
  }

  //------------------------------------------------------------------

  getConfig(fields) {
    console.log('>MyFlashDS.getConfig, ', fields);
    return axios.get(process.env.VUE_APP_ROOT_API+'/config?fields='+fields);
  }
  saveConfig(data) {
    console.log('>MyFlashDS.saveConfig');
    return axios.put(process.env.VUE_APP_ROOT_API+'/config', data);
  }
/*
  saveUser(data) {
    return axios.post(process.env.VUE_APP_ROOT_API+'/users', data);
  }

*/

}

export default new MyFlashDS();
