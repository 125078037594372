import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'fr': {
      test: "fr-test",
      list_tuto: "Liste des tutoriels",
      add: "Ajouter",
      search: "Rechercher",
      remove_all: "Tout supprimer",
      click_tuto: "Cliquer sur un tuto...",
      newGroup: "Nouvelle classe",
      newOrg: "Nouvelle organisation",
      admin: "Administration",
      organisation: "Organisation",
      Prof: "Professeur",
      createdAt: "Création",
      competence: "Compétence",
      technoAdd: "Ajout Techno",
      education: "Education",
      synthese: "Synthese",
      questions: "Questionnaire",
      roles: "Roles",
      history: "Historique",

      save: "Sauver",

      connexion: "Connexion",
      connect: "Se connecter",
      logout: "Déconnexion",
      pseudo: "Pseudo",
      validate: "Valider",
      welcome: "Bonjour, ",
      subscribe: "Souscrire",
      gomyflash: "Bienvenue sur coursGPT",
      validation: "En validant ce formulaire, vous acceptez les ",
      cgu: "conditions générales d'utilisation",
      inscription: "Inscription",
      return: "Retour",
      whatisit: "Qu'est-ce que c'est ?",
      email: "Adresse email",
      password: "Mot de passe",
      confirmpassword: "Confirmer Mot de Passe",
      passwordforgot: "Mot de passe oublié ?",
      administration: 'Administration',
      connectflash: "Connecter ce Flash",
      seeprofile: "Voir le profil",
      addcontact: "Ajouter le contact",
      addcircle: "My Circle",
      addsmart: "Smartphone",
      accept: "Accepter",

      public_vue: 'Visible par tout le monde',
      private_vue: 'Visible par les personnes que vous définissez',
      security_vue: 'Visible par les services de sécurité',

      // Tags
      id: "Identifiant",
      no: "Numéro",
      customer: "Partenaire",
      customers: "Partenaires",
      tags: "Tags",
      users: "Utilisateurs",
      isAdmin: "Admin ?",
      isSecurity: "Sécurité ?",
      isSubscribed: "Abonnement ?",
      isCircle: "Circle+ ?",
      isValidated: "Validé ?",
      owner: "Propriétaire",

      // Fields
      b_presentation: 'Présentation',
        presentation: '',
      b_identity: 'Identité',
        name: 'Nom',
        surname: 'Prénom',
        birthdate: 'Date naissance',
      b_coord: 'Coordonnées',
        address: 'Adresse',
        cp: 'CP',
        town: 'Ville',
      b_network: 'Réseaux sociaux',
        facebook: 'Facebook',
        instagram: 'Instagram',
        twitter: 'Twitter',
      b_interest: "Centres d'intéret",
        films: 'Films',
        books: 'Livres',
        series: 'Séries',
      b_sante: 'Santé',
        allergy: 'Allergies',
        treatment: 'Traitements',
        operation: 'Prénom',
      b_professionel: 'Professionnel',
        job: 'Métier',
        company: 'Société',
        lastexp: 'Dernière expérience professionnelle',

    },
    'en': {
      test: "en-test",
      list_tuto: "Tutorials list",
      add: "Add",
      search: "Search",
      remove_all: "Remove all",
      click_tuto: "Please click on a Tutorial...",
    },

};

const i18n = new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;
