<template>
  <div class="container justify-content-center">

      <b-modal id="modal-cgu" ref="cgu" size="xl" scrollable title="Conditions Générales">
        <div v-html="cgu"/>
      </b-modal>


    <div>
        <CircleComp :text="$t('inscription')"/>

        <div class="row justify-content-center md-form md-outline">
            <input @change="myChange()" v-model="user.pseudo" :placeholder="$t('pseudo')" />
        </div>
        <div class="row justify-content-center md-form md-outline">
            <input @change="myChange()" v-model="user.email" :placeholder="$t('email')" />
        </div>
        <div class="row justify-content-center md-form md-outline">
            <input @change="myChange()" type="password" v-model="user.password" :placeholder="$t('password')" />
        </div>
        <div class="row justify-content-center md-form md-outline">
            <input @change="myChange()" type="password" v-model="user.password2" :placeholder="$t('confirmpassword')" />
        </div>
        <!--
        <div class="row justify-content-center">
            <input v-model="user.validation" type="checkbox" />{{$t('validation')}} <a @click="openModal()" href="#">{{$t('cgu')}}</a>
        </div>
        -->

        <div class="row justify-content-center" v-if="this.group">
            Groupe : {{this.group.name}} ({{this.group.year}})<br/>
        </div>

        <div class="row justify-content-center">
           <div class=''><button @click="doValidate()" type="button" :disabled="!user.validation || !user.pseudo || !user.email || user.password!=user.password2" class="btn btn-primary buttons text-uppercase">{{$t('validate')}}</button></div>
        </div>
        <div class="row justify-content-center">
           <div class=''><button @click="doReturn()" type="button" class="btn btn-secondary buttons text-uppercase">{{$t('return')}}</button></div>
        </div>
    </div>
  </div>
</template>

<script>
import MyFlashDS from "../services/MyFlashDS";
import CircleComp from '@/components/CircleComp.vue'

export default {
  data(){
      return {
        user: {validation:true},
        cgu: '<b>TBD</b>...',
      }
  },
  name: "UserRegister",
  props: ['group'],
  components: {
    CircleComp,
  },
  methods: {
    doValidate(){
      MyFlashDS.registerUser(this.user);
      this.$emit('doReturn', "OK");
    },
    doReturn(){
      this.$emit('doReturn', "OK");
    },
    myChange() {
    },
    openModal() {
      MyFlashDS.getConfig('cgu').then(res => {
        this.cgu=res.data.cgu;
        this.$refs.cgu.show();
      });
    },
  },
  mounted() {
        if (this.group) {
            console.log('GROUP register', this.group);
            MyFlashDS.groupFindOne(this.group).then(res=>{
                console.log(res.data);
                this.user.group=this.group;
                this.group = res.data
            });
        }
  }
}
</script>


<style scoped>
button {
    cursor: pointer;
    width: 30vh;
    margin-top: 2vh;
    padding-top: 1vh;
    padding-bottom: 1vh;
    font-size: 1rem;
    font-family: ITCAvantGarde, serif;
}

img.logo {
    object-fit: cover;
    height: 40vh;
}

input {
    border: 1px solid var(--couleur-principale);
    width: 45vh;
}
input[type='checkbox'] {
    width: 3vh;
}


</style>
