import Vue from "vue";
import Router from "vue-router";
//import { createRouter, createWebHistory } from 'vue-router'

//import AdminView from '@/views/AdminView.vue'
import MainView from '@/views/MainView.vue'
import UserView from '@/views/UserView.vue'
import UserAdminView from '@/views/UserAdminView.vue'
import UserValidationView from '@/views/UserValidationView.vue'
//import FlashView from '@/views/FlashView.vue'
//import TestComp from '@/views/TestComp.vue'


console.log('ROUTER', Router)
Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      alias: "/logout",
      name: "main",
      component: MainView,
    },
    {
      path: "/register",
      name: "register",
      component: MainView,
    },
    {
      path: "/admin/:id",
      name: "Administration",
      component: ()=>import('@/views/AdminView.vue'), //AdminView,
    },


    {
      path: "/user",
      name: "User1",
      component: UserView,
    },
    {
      path: "/user/:type",
      name: "User2",
      component: UserView,
    },
    {
      path: "/contacts/",
      name: "Contacts",
      component: UserAdminView,
    },
    {
      path: "/validate/",
      name: "Validation",
      component: UserValidationView,
    },
  ]
});
